<template>
  <div class="cleaning-images">
    <div v-if="images.length" class="image-list">
      <ImagePreview
        v-for="(item, index) in images"
        :key="index"
        :index="index"
        :data="item"
        @onRemove="onRemove"
      />
    </div>
    <button
      type="button"
      class="btn btn-primary btn-sm"
      @click="onAdd"
    >画像追加</button>
  </div>
</template>

<script>
import ImagePreview from './ImagePreview.vue';

export default {
  props: [
    'default'
  ],
  components: {
    ImagePreview
  },
  data () {
    return {
      images: [],
    };
  },
  mounted() {
    if (this.default && this.default.length) {
      this.images = [...this.default]
    }
  },
  computed: {
  },
  methods: {
    onAdd() {
      this.images.push({})
    },
    onRemove(index) {
      console.log(index)
      this.images.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.image-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 15px;
  margin-bottom: 10px;
}
</style>
