<template>
  <div>
    <announce-group-form
      v-model="data"
      name="details"
    ></announce-group-form>
  </div>
</template>

<script>
export default {
  props: [
  ],
  components: {
  },
  data () {
    return {
      data: {
        content: '',
        option: '1',
        options: '',
        children: [],
      }
    };
  },
  mounted() {
  },
  computed: {
    options: function () {
      if (this.value == 1) return '参加,不参加'
      if (this.value == 2) return '賛成,反対'
      if (this.value == 3) return 'はい,いいえ'
      if (this.value == 4) return 'Yes,No'
      return ''
    }
  },
  watch: {
    data: {
      handler: function (val, oldVal) {
        console.log(val)
      },
      deep: true,
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
