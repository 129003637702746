<template>
  <div>
    <div>
      <button type="button" class="btn btn-primary btn-sm"
        @click="$refs.fileInput.click()"
      >画像選択</button>
      <button type="button" class="btn btn-danger btn-sm"
        @click="onRemove"
      >画像削除</button>
    </div>
    <div class="image-preview">
      <img v-if="url" :src="url" />
    </div>
    <input
      type="file"
      ref="fileInput"
      name="images[]"
      accept="image/*"
      @change="onChange"
    />
    <input
      v-if="data.id && isDeleted"
      type="hidden"
      name="deletes[]"
      :value="data.id"
    />
  </div>
</template>

<script>
export default {
  props: [
    'index', 'data'
  ],
  components: {
  },
  data () {
    return {
      url: '',
      isDeleted: false,
    };
  },
  mounted() {
    const { id, url } = this.data
    if (url) {
      this.url = url
    }
  },
  computed: {
  },
  methods: {
    onChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    onRemove() {
      this.url = ''
      this.$refs.fileInput.value = null
      this.isDeleted = true
    },
  }
}
</script>

<style lang="scss" scoped>
input[type=file] {
  display: none;
}
.image-preview {
  width: 200px;
  height: 200px;
  margin-top: 10px;
  border: 1px solid #666;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
