<template>
  <div>
    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input options-4" type="radio" name="options" value="5" v-model="value">参加/不参加
      </label>
    </div>
    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input options-2" type="radio" name="options" value="2" v-model="value">賛成/反対
      </label>
    </div>
    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input options-4" type="radio" name="options" value="4" v-model="value">任意
      </label>
    </div>
    <input
      v-if="value != 4"
      type="hidden"
      name="detail[options]"
      v-model="options"
    />
    <div v-else class="ml-3">
      <p class="mb-1"><small>選択肢を(,)で区分してください。</small></p>
      <input name="detail[options]" type="text" class="form-control" placeholder="はい,いいえ" required/>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'name'
  ],
  components: {
  },
  data () {
    return {
      value: 5,
    };
  },
  mounted() {
  },
  computed: {
    options: function () {
      if (this.value == 1) return 'Yes,No'
      if (this.value == 2) return '賛成,反対'
      return ''
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
