require('./bootstrap');

$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  }
});

window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('cleaning-images', require('./components/CleaningImages.vue').default);
Vue.component('fishing-images', require('./components/FishingImages.vue').default);
Vue.component('announce-options', require('./components/AnnounceOptions.vue').default);
Vue.component('announce-form', require('./components/AnnounceForm.vue').default);
Vue.component('announce-group-form', require('./components/AnnounceGroupForm.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
  el: '#page-wrapper',
});
