<template>
  <div class="mt-3">
    <div v-if="parentOptions" class="form-group row">
      <label class="col-auto col-form-label w-fix-120 required">送信条件</label>
      <div class="col-auto flex-fill">
        <select :name="`${name}[parent_option]`" class="form-control">
          <option
            v-for="(item, index) in parentOptions"
            :key="index"
            :value="item"
          >{{ item }}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-auto col-form-label w-fix-120 required">内容</label>
      <div class="col-auto flex-fill">
        <textarea :name="`${name}[content]`" class="form-control" rows="3" required v-model="data.content"></textarea>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-auto col-form-label w-fix-120 required">選択肢</label>
      <div class="col-auto flex-fill">
        <div class="form-check">
          <label class="form-check-label">
            <input class="form-check-input options-4" type="radio" :name="`${name}[option]`" value="1" v-model="data.option">参加/不参加
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input class="form-check-input options-2" type="radio" :name="`${name}[option]`" value="2" v-model="data.option">賛成/反対
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input class="form-check-input options-3" type="radio" :name="`${name}[option]`" value="3" v-model="data.option">任意
          </label>
        </div>
        <div v-if="data.option == '3'" class="ml-3">
          <p class="mb-1"><small>選択肢を(/)で区分してください。</small></p>
          <input :name="`${name}[options]`" type="text" class="form-control" placeholder="はい/いいえ" required v-model="data.options"/>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input class="form-check-input options-4" type="radio" :name="`${name}[option]`" value="4" v-model="data.option">選択なし
          </label>
        </div>
      </div>
    </div>
    <!-- <div class="mt-3">
      <button type="button" class="btn btn-primary btn-sm"
        @click="add"
        :disabled="options.length == 0"
      >次の質問を追加</button>
      <button v-if="parentOptions" type="button" class="btn btn-danger btn-sm"
        @click="remove"
      >削除</button>
      <div class="ml-5">
        <announce-group-form
          v-for="(item, index) in data.children"
          :key="index"
          v-model="data.children[index]"
          :parentOptions="options"
          :name="`${name}[children][${index}]`"
          @remove="() => removeChild(index)"
        ></announce-group-form>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: [
    'value',
    'parentOptions',
    'name',
  ],
  components: {
  },
  data () {
    return {
      data: this.value,
    };
  },
  mounted() {
  },
  computed: {
    options: function () {
      if (this.data.option == '3') return this.data.options.split('/')
      else if (this.data.option == '2') return ['賛成', '反対']
      else if (this.data.option == '1') return ['参加', '不参加']
      else return []
    }
  },
  watch: {
    data: {
      handler: function (val, oldVal) {
        this.$emit('input', val)
      },
      deep: true,
    },
    value: {
      handler: function (val, oldVal) {
        this.data = val
      },
      deep: true,
    }
  },
  methods: {
    add () {
      this.data.children.push({
        content: '',
        option: '1',
        options: '',
        children: [],
      })
    },
    remove () {
      this.$emit('remove')
    },
    removeChild (index) {
      console.log(index)
      this.data.children.splice(index, 1)
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
